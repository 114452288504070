* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  min-height: 100vh;
}

#root {
  height: 100vh;
}

/* Não aparecer icone de mostrar senha duplicado nos navegadores Edge e internet explorer */
/* input::-ms-reveal,
input::-ms-clear {
  display: none;
} */
